import React from "react";
import { Breadcrumb } from "gatsby-plugin-breadcrumb"
import '../../components/Breadcrumb.css'
import styled from "styled-components";
import media from "styled-media-query";

import Layout from "../../components/Layout";
import Facilities from "../../components/Facilities";

import MainImage1 from "../../img/facility-1.jpg";
import BgImage1 from "../../img/news-bg.png";

const FacilityPage = ({ pageContext }) => {
  const { breadcrumb: { crumbs }} = pageContext;

  return (
    <Layout>
      {/*
      パンくず
      メインビジュアル
      タイトル
      2カラム紹介
      2カラムボタン
      詳細施設案内
      Googleマップ埋め込み
      共通の施設案内
      パンくず
      */}
      <Body>
      <Breadcrumb crumbs={crumbs} crumbSeparator="/" crumbLabel="施設案内"  />
      <ImageBig src={MainImage1}></ImageBig>
      <MidashiBg>
        <Midashi>施設案内</Midashi>
      </MidashiBg>
      <p>
      NAWATE PROJECTでは<br />
      Guesthouse&Loungeとりいくぐる、NAWATE、奉還町４丁目ラウンジ・カドを運営しています。<br />
      まちに古くからある建物を受け継ぎ、少しずつ自分たちの手で再生しながら、<br />
      働く、滞在する、住む、お店をもつ、食べる・飲む<br />
      など暮らしに関わる全般を自分たちの目線で考えるために運営を続けています。
      </p>
      </Body>
      <Facilities />
      <FooterDiv>
        <Breadcrumb crumbs={crumbs} crumbSeparator="/" crumbLabel="施設案内"  />
      </FooterDiv>
    </Layout>
  );
}

export default FacilityPage;

const Body = styled.div`
  margin: 0 5vw 3em 5vw;
  
`;

const MidashiBg = styled.div`
  width: 100%;
  background-image: url(${BgImage1});
  background-repeat: no-repeat;
  background-position: 50% 100%;
  background-size: 100%;
  text-align: center;
  ${media.lessThan("medium")`
    /* screen width is less than 768px (medium) */
    height: 8em;
    margin: 0 0 2em 0;
    padding: 2em 15vw 0 15vw;
  `}
  ${media.greaterThan("medium")`
    /* screen width is greater than 1170px (large) */
    height: 15em;
    margin: 0 0 5em 0;
    padding: 5em 15vw 0 15vw;
  `}
`;

const FooterDiv = styled.div`
  margin: 3vh 5vw 3vh 5vw;
`;

const ImageBig = styled.img`
  width: 100%;
  border-radius: 10px;
  margin: 0 0 3vh 0;
  object-fit: cover;
  ${media.lessThan("medium")`
    /* screen width is less than 768px (medium) */
    height: 30vh;
  `}
  ${media.greaterThan("medium")`
    /* screen width is greater than 1170px (large) */
    height: 80vh;
  `}
`;

const Midashi = styled.p`
  font-family: 'Noto Sans JP', sans-serif;
  font-weight: 700;
  ${media.lessThan("medium")`
    /* screen width is less than 768px (medium) */
    font-size: 20pt;
  `}
  ${media.greaterThan("medium")`
    /* screen width is greater than 1170px (large) */
    font-size: 28pt;
  `}
`;
